import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';

import './Tooltip.scss';

const useStyles = makeStyles(theme => ({
  customTooltip: {
    // I used the rgba color for the standard "secondary" color
    // color: '#5e6278 !important',
    // backgroundColor: 'rgba(0, 133, 119, 0.5)',
  },
  customArrow: {
  	// color: '#FFFFFF !important',
  },
}));

const CustomTooltip = withStyles({
  tooltip: {
    // color: "#5e6278",
    // backgroundColor: "#FFFFFF",
    // border: '1px solid #eff2f5',
    padding: '10px'
  },
  arrow:{},
})(Tooltip);

export default (props) => {
	const classes = useStyles();
	const placement = (props.placement ? props.placement : 'bottom');
	const textContent = (props.tooltipName?.props?.children ?
		props.tooltipName?.props?.children : props.tooltipName
	);

	return <CustomTooltip 
		classes={{
		tooltip: classes.customTooltip,
		arrow: classes.customArrow,
		}}
		placement={placement}
		title={<span style={{fontSize: '13px', color: 'FFFFFF !important'}}>{textContent}</span>}
		arrow >
			{props.tooltipContent}
		</CustomTooltip>
};