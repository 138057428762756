import GlobalFunctionUtils from '../GlobalFunctionUtils';
import ImportFileService from '../../services/importfileservice';

export const ImportFileHelper = {
    Import: async function (file, module) {
    	let returnData = {
    		success: false,
    		msg: "Import Failed",
    	};

    	let importResponse = null;

    	switch(module) {
            case "patients":
            	importResponse = await ImportFileService.importPatients(file, 1);
            break;

            case "users":
            	importResponse = await ImportFileService.importUsers(file, 1);
            break;

            case "clinicalServices":
               	importResponse = await ImportFileService.importClinicalServices(file, 6);
            break;

            case "inventory":
               	importResponse = await ImportFileService.importInventories(file, 1);
            break;

            case "inventoryMaster":
                importResponse = await ImportFileService.importInventoriesMaster(file, 1);
            break;

            case "suppliers":
                importResponse = await ImportFileService.importSuppliers(file);
            break;

            case "insurances":
                importResponse = await ImportFileService.importInsurances(file);
            break;
        }

        if(importResponse != null) {
	        returnData = {
	    		success: (importResponse.isSuccess ? true : false),
	    		msg: (importResponse.errorMessage != null ? importResponse.errorMessage : importResponse.data.message),
	    	};
	    }

        return returnData;
    },
    getFileExtension: function (filename) {
        return filename.split('.').pop().toLowerCase();
    },
}

export default ImportFileHelper;