import React, { createContext, useContext, useState } from 'react'
import Loader from '../components/CommonComponents/Loader/Loader';

// type LoadingScreenProps = {
//     loadingState: boolean;
//     setLoadingState: (loadingState: boolean) => void
// }

// export const LoadingScreenContext = createContext<LoadingScreenProps>({
export const LoadingScreenContext = createContext({
    loadingState: false,
    setLoadingState: () => null,
})
export const useLoadingScreen = () => useContext(LoadingScreenContext)

export const LoadingScreenProvider = ({ children }: { children: React.ReactNode }) => {
    const [loadingState, setLoadingState] = useState(false)

    return (
        <LoadingScreenContext.Provider value={{ loadingState, setLoadingState }}>
            {loadingState && <Loader />}
            {children}
        </LoadingScreenContext.Provider>
    )
}