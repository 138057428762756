import 'core-js/es/map';
import 'core-js/es/set';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
// import ReactDOM from 'react-dom';
import { SnackbarProvider, MaterialDesignContent, useSnackbar } from 'notistack';
import { CloseOutlinedIcon } from './utilities/Icons/Icons';
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import configureStore from './store';
import { CookiesProvider } from 'react-cookie';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { SessionManagerProvider } from './store/SessionManager';
import {
    BrowserRouter as Router,
    Link,
    generatePath
} from 'react-router-dom';


var { store } = configureStore();
const root = ReactDOM.createRoot(document.getElementById("root"));
// const { enqueueSnackbar, closeSnackbar } = useSnackbar();
// const action = snackbarId => (
//     <>
//         <button onClick={() => { closeSnackbar(snackbarId) }}>
//             <CloseOutlinedIcon />
//         </button>
//     </>
// );

root.render(
    <Provider store={store}>
            <App />
    </Provider>
);

serviceWorker.unregister();