import React, { useState, Suspense, useEffect } from 'react';
import './App.scss';
import { withCookies, useCookies } from 'react-cookie';
import {
    QueryCache,
    QueryClient,
    QueryClientProvider,
  } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import Loader from './components/CommonComponents/Loader/Loader';

import { LoadingScreenProvider } from './store/LoadingManager';

import PageLayout from './components/CommonComponents/Layout/PageLayout/PageLayout';
import { styled } from '@mui/material/styles';
import LocalStorageHelper from './utilities/LocalStorageHelper';
import { MaterialDesignContent, SnackbarProvider, useSnackbar } from 'notistack';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnReconnect: true,
            staleTime: 1000 * 60 * 5, // Prevent staleness
            cacheTime: 1000 * 60 * 5, // Keep the data indefinitely
            keepPreviousData: true,
            // networkMode: 'offlineFirst',
            // refetchOnMount: false,
            refetchOnWindowFocus: false,
            // refetchIntervalInBackground: false
        },
    },
    queryCache: new QueryCache({
        onError: (error) => {
            console.error('Caching Error: ', error.message);
        }
    }),
})

const App = () => {
    const userData = LocalStorageHelper.getUserData();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    // Use states
    const [isLoggedIn, setIsLoggedIn] = useState(LocalStorageHelper.isLoggedIn());
    const [isDarkMode, setDarkMode] = useState(LocalStorageHelper.isDarkMode() === 'true');
    const [GettingSettings, setGettingSettings] = useState(false);

    //Variables
    const [cookies, setCookie, removeCookie] = useCookies(['navigatedPages']);
    if (isDarkMode) {
        document.body.classList.add('darkMode');
    }

    useEffect(() => {
        setGettingSettings(true);
        // getSystemGeneralSettings();
    }, []);

    // Event Handler Fuctions

    const loginChecker = async () => {
        let _proceed = false;
        let _pathName = window.location.pathname;
    }

    const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
        '&.notistack-MuiContent-success': {
            backgroundColor: '#00bd9d',
        },
        '&.notistack-MuiContent-error': {
            backgroundColor: '#f06548',
        },
    }));

    //Rendering Functions


    return <LoadingScreenProvider>
        <QueryClientProvider client={queryClient}>
            <SnackbarProvider
            Components={{
                success: StyledMaterialDesignContent,
                error: StyledMaterialDesignContent,
            }}
            // persist={true}
            // action
            autoHideDuration={7000} >
                <div className={`App${(isDarkMode ? ' darkMode' : '')}`}>
                    <Suspense fallback={<></>}>
                        <PageLayout darkMode={isDarkMode} setDarkMode={setDarkMode}/>
                    </Suspense>
                </div >
            </SnackbarProvider>
        </QueryClientProvider >
    </LoadingScreenProvider>
}

export default withCookies(App);