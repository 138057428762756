import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import AccessHelper from '../../../../utilities/Helpers/AccessHelper';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ClickAwayListener, Collapse, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import NavigationSideMenuItem from './NavigationSideMenuItem';
import LocalStorageHelper from '../../../../utilities/LocalStorageHelper';
import React, { useState, useRef, useEffect } from 'react';

const drawerWidth = 270;
const versionNum = process.env.REACT_APP_VERSION

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        variants: [
            {
                props: ({ open }) => open,
                style: {
                    ...openedMixin(theme),
                    '& .MuiDrawer-paper': openedMixin(theme),
                },
            },
            {
                props: ({ open }) => !open,
                style: {
                    ...closedMixin(theme),
                    '& .MuiDrawer-paper': closedMixin(theme),
                },
            },
        ],
    }),
);

export default function NavigationSideMenu({ openDrawer, handleClosePopper, darkMode, clickIconMenu }) {
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = useLocation();
    const clinicData = LocalStorageHelper.getClinicData();
    let _MenuItems = AccessHelper.hasAccessMenu();
    const subMenuLeftPadding = 8
    const webAppLogo = clinicData ? (openDrawer ?
        (darkMode ?
            clinicData?.sidebarLogoDarkMode ?? '' : clinicData?.sidebarLogoLightMode ?? ''
        ) : (darkMode ?
            clinicData?.sidebarSmallLogoDarkMode ?? '' : clinicData?.sidebarSmallLogoLightMode ?? ''
        )
    ) : '';

    //refs
    const menuLoadedRef = useRef(false)

    //states
    const [openSubMenuArr, setOpenSubMenuArr] = useState([]); 

    const getSelectedPath = (items, ancestors = []) => {
        for (let item of items) {
            if (item.navigateTo === pathname) {
                return ancestors.concat(item.tabName);
            }
            if (item.hasSubMenu) {
                const found = getSelectedPath(item.subMenuItems, ancestors.concat(item.tabName));
                if (found) {
                    return found;
                }
            }
        }
        return undefined;
    }

    //if from new tab or page refresh
    if (location.key == 'default' && _MenuItems && _MenuItems.length > 0 && !menuLoadedRef.current) {
        const arr = getSelectedPath(_MenuItems)
        const arrDict = arr?.map(x => ({name: x, isOpen: true})) ?? []
        setOpenSubMenuArr(arrDict)
        menuLoadedRef.current = true
    } 

    const handleClickMenu = (event, menuItem) => {
        const arr = JSON.parse(JSON.stringify(openSubMenuArr));
        //double check if array
        if (!Array.isArray(arr))
            arr = []

        //toggle collapse - open submenu
        if (isSelected(menuItem) && menuItem.hasSubMenu) {
            const itemIndex = arr.findIndex(x => x.name == menuItem.tabName)
            if (itemIndex != null && itemIndex != undefined) {
                arr[itemIndex].isOpen = !arr[itemIndex].isOpen
            }

            //check if not a child
        } else if (arr.length > 0 && arr[arr.length - 1].name != menuItem.parentName) {
            while (arr.length > 0 && arr[arr.length - 1].name != menuItem.parentName)
                arr.pop()
            arr.push({ name: menuItem.tabName, isOpen: true })

            //default - add to selected
        } else {
            arr.push({ name: menuItem.tabName, isOpen: true })
        }

        setOpenSubMenuArr(arr)

        if (!openDrawer) {
            if (menuItem?.subMenuItems?.length > 0)
                clickIconMenu(event, menuItem, arr)
            else {
                navigate(menuItem.navigateTo,
                    {
                        state: {
                            tabName: menuItem.tabName,
                            parentName: menuItem.parentName
                        }
                    });
                handleClosePopper()
            }
        }
    };

    //checker if included in selected array tree
    const isSelected = (menuItem) => {
        const names = openSubMenuArr?.map(x => x.name) ?? [];
        return names.includes(menuItem.tabName)
    }

    //checker if collapsed
    const isOpen = (menuItem) => {
        const item = openSubMenuArr?.find(x => x.name == menuItem.tabName) ?? undefined;
        return item?.isOpen ?? false
    }

    const renderSubMenu = (menuItems, menuLeftMargin) => {
        return <List component="div" disablePadding>
            {menuItems?.map((subMenuItem, index) => (
                <>
                    <NavigationSideMenuItem
                        isSelected={isSelected(subMenuItem)}
                        isOpenSubMenu={isOpen(subMenuItem)}
                        leftPadding={menuLeftMargin}
                        isDrawerOpen={openDrawer}
                        menuItem={subMenuItem}
                        handleClickMenu={(e) => {
                            handleClickMenu(e, subMenuItem)
                        }} />
                    {subMenuItem.hasSubMenu && openDrawer && <Collapse in={isOpen(subMenuItem)} timeout="auto" unmountOnExit>
                        {subMenuItem.hasSubMenu && renderSubMenu(subMenuItem.subMenuItems, menuLeftMargin + 2)}
                    </Collapse>}
                </>
            ))}
        </List>
    }

    const renderDrawerHeader = <DrawerHeader>
        <div>
            <Link className="logoLink" href="/">
                <img style={{ maxWidth: '100%', margin: '30px 0px 20px 0px' }} src={webAppLogo} />
            </Link>
        </div>
    </DrawerHeader>;

    const renderDrawerList = <List>
        {_MenuItems?.map((menuItem, index) => (
            <ListItem key={menuItem.tabName} disablePadding
                sx={{ display: 'block', p: 0 }}>
                <NavigationSideMenuItem
                    isSelected={isSelected(menuItem)}
                    isOpenSubMenu={isOpen(menuItem)}
                    leftPadding={2}
                    isDrawerOpen={openDrawer}
                    menuItem={menuItem}
                    handleClickMenu={(e) => {
                        handleClickMenu(e, menuItem);
                    } } />

                {menuItem.hasSubMenu && openDrawer && <Collapse in={isOpen(menuItem)} timeout="auto" unmountOnExit>
                    {renderSubMenu(menuItem.subMenuItems, subMenuLeftPadding)}
                </Collapse>}
            </ListItem>
        ))}
    </List>;

    return (
        <Drawer variant="permanent"
            open={openDrawer}
            PaperProps={{ sx: { backgroundColor: darkMode ? '#212529' : '#f6f8fa' } }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', marginBottom: '15px' }}>
                <div>
                {renderDrawerHeader}
                {renderDrawerList}
                 </div>
            {openDrawer ?
                <div style={{ textAlign: 'center', fontSize: '12px', color: 'gray'}}>
                    <span>{(versionNum && versionNum != "" ? "Version " + versionNum : "")}</span>
                </div> : <></>}
            </div>
        </Drawer>
    );
}