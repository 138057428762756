
import axios from "axios";
import LocalStorageHelper from "../utilities/LocalStorageHelper";
import { RouteEnum } from "../utilities/Enums/RouteEnums";
const axiosClient = axios.create();

axiosClient.defaults.baseURL = process.env.REACT_APP_API_URL + '/api/'; //"https://reqres.in/api"; //add here

axiosClient.defaults.headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Accept: "application/json",
    "ngrok-skip-browser-warning": "69420",
};

// axiosClient.defaults.timeout = 2000 * 5;
axiosClient.defaults.timeout = 2000 * 20;
// axiosClient.defaults.withCredentials = (localStorage.getItem('userData') !== null ? true : false);

axiosClient.defaults.headers["Content-Type"] = 'application/json';
axiosClient.defaults.headers["Accept"] = 'application/json';
axiosClient.defaults.headers["Access-Control-Allow-Origin"] = '*';

function validateResponse(response) {
    if (response == null || response.data == null) {
        throw Error("No response from server!");
    }

    if (!response.data.success) {
        throw Error(response.data.message);
    }

    return { data: response.data, isSuccess: true };

}

function preettifyErrorMessage(error) {
    if (error && error.response && error.response.status) {
        if (error.response.status == 401) {
            // LocalStorageHelper.clearLocalStorage()
            window.location.replace(RouteEnum.LOGIN)
            return "Session is expired. Please login again."
        } else if (error.response.status == 404) {
            return "URL is not found, please go back"
        } else if (error.response.status == 400) {
            return "Incorrect data submitted, please report this issue"
        } else if (error.response.status >= 500 && error.response.status <= 599) {
            return "Server error, please report this issue"
        }
    }
    return error.message;
}

export async function getRequest(URL, cancelSourceToken = "") {
    axiosClient.defaults.headers["Authorization"] = (LocalStorageHelper.isLoggedIn() ? "bearer " + LocalStorageHelper.getToken() : "");
    axiosClient.defaults.headers["Content-Type"] = 'application/json';
    axiosClient.defaults.headers["Accept"] = 'application/json';

    return axiosClient
        .get(`/${URL}`, {
            cancelToken: cancelSourceToken,
        })
        .then((response) => {
            return validateResponse(response);
        })
        .catch((err) => {
            return { errorCode: err && err.response && err.response.status ? err.response.status : 201, errorMessage: preettifyErrorMessage(err) };
        });
}

export async function postRequest(URL, payload) {
    axiosClient.defaults.headers["Authorization"] = (LocalStorageHelper.isLoggedIn() ? "bearer " + LocalStorageHelper.getToken() : "");
    axiosClient.defaults.headers["Content-Type"] = 'application/json';
    axiosClient.defaults.headers["Accept"] = 'application/json';

    return axiosClient
        .post(`/${URL}`, payload)
        .then((response) => {
            return validateResponse(response);
        })
        .catch((err) => {
            return { errorCode: err && err.response && err.response.status ? err.response.status : 201, errorMessage: preettifyErrorMessage(err) };
        });
}

export async function putRequest(URL, payload) {
    axiosClient.defaults.headers["Authorization"] = (LocalStorageHelper.isLoggedIn() ? "bearer " + LocalStorageHelper.getToken() : "");
    axiosClient.defaults.headers["Content-Type"] = 'application/json';
    axiosClient.defaults.headers["Accept"] = 'application/json';

    return axiosClient
        .put(`/${URL}`, payload)
        .then((response) => {
            return validateResponse(response);
        })
        .catch((err) => {
            return { errorCode: err && err.response && err.response.status ? err.response.status : 201, errorMessage: preettifyErrorMessage(err) };
        });
}

export async function deleteRequest(URL) {
    axiosClient.defaults.headers["Authorization"] = (LocalStorageHelper.isLoggedIn() ? "bearer " + LocalStorageHelper.getToken() : "");
    axiosClient.defaults.headers["Content-Type"] = 'application/json';
    axiosClient.defaults.headers["Accept"] = 'application/json';

    return axiosClient
        .delete(`/${URL}`)
        .then((response) => {
            return validateResponse(response);
        })
        .catch((err) => {
            return { errorCode: err && err.response && err.response.status ? err.response.status : 201, errorMessage: preettifyErrorMessage(err) };
        });
}

export async function importRequest(URL, payload) {
    axiosClient.defaults.headers["Authorization"] = (LocalStorageHelper.isLoggedIn() ? "bearer " + LocalStorageHelper.getToken() : "");
    axiosClient.defaults.headers["Content-Type"] = 'multipart/form-data';
    axiosClient.defaults.headers["Accept"] = 'multipart/form-data';

    const formData = new FormData();
    formData.append("file", payload);

    return axiosClient
        .post(`/${URL}`, formData)
        .then((response) => {
            return validateResponse(response);
        })
        .catch((err) => {
            return { errorCode: err && err.response && err.response.status ? err.response.status : 201, errorMessage: preettifyErrorMessage(err) };
        });
}

export async function exportRequest(URL) {
    axiosClient.defaults.headers["Authorization"] = (LocalStorageHelper.isLoggedIn() ? "bearer " + LocalStorageHelper.getToken() : "");
    axiosClient.defaults.headers["Content-Type"] = 'application/octet-stream';
    return axiosClient
        .get(`/${URL}`, {
            responseType: 'blob'
        })
        .then((response) => {
            return response;
        })
        .catch((err) => {
            return { errorCode: err && err.response && err.response.status ? err.response.status : 201, errorMessage: preettifyErrorMessage(err) };
        });
}

export async function uploadRequest(URL, payload) {
    axiosClient.defaults.headers["Authorization"] = (LocalStorageHelper.isLoggedIn() ? "bearer " + LocalStorageHelper.getToken() : "");
    axiosClient.defaults.headers["Content-Type"] = 'multipart/form-data';
    axiosClient.defaults.headers["Accept"] = 'multipart/form-data';

    return axiosClient
        .put(`/${URL}`, payload)
        .then((response) => {
            return validateResponse(response);
        })
        .catch((err) => {
            return { errorCode: err && err.response && err.response.status ? err.response.status : 201, errorMessage: preettifyErrorMessage(err) };
        });
}

export async function postUploadRequest(URL, payload) {
    axiosClient.defaults.headers["Authorization"] = (LocalStorageHelper.isLoggedIn() ? "bearer " + LocalStorageHelper.getToken() : "");
    axiosClient.defaults.headers["Content-Type"] = 'multipart/form-data';
    axiosClient.defaults.headers["Accept"] = 'multipart/form-data';

    return axiosClient
        .post(`/${URL}`, payload)
        .then((response) => {
            return validateResponse(response);
        })
        .catch((err) => {
            return { errorCode: err && err.response && err.response.status ? err.response.status : 201, errorMessage: preettifyErrorMessage(err) };
        });
}