import React from 'react';
import {
    useLocation,
} from 'react-router-dom';
import CryptoJS from "crypto-js";

const secretPass = "XkhZG4fW2t2W";

const GlobalFunctionsUtils = {
    encodeID: function (str) {
        return str.replaceAll("/", "%2F")
    },
    decodeID: function (str) {
        return str.replaceAll("%2F", "/")
    },
    getRanHex: function (size) {
        return [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('')
    },
    withLeadingZeros: function (num, totalLength) {
        return String(num).padStart(totalLength, '0');
    },
    encryptText: function (textString) {
        return CryptoJS.AES.encrypt(
            JSON.stringify(textString),
            secretPass
        ).toString();
    },
    decryptText: function (textString) {
        const bytes = CryptoJS.AES.decrypt(textString, secretPass);

        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    },
    percentToDecimal: function (percentStr) {
        return parseFloat(percentStr) / 100;
    },
    sortAscending: function (object, fieldName) {
        return object.sort((a, b) => (a[fieldName] > b[fieldName]) ? 1 : ((b[fieldName] > a[fieldName]) ? -1 : 0))
    },
    sortDesscending: function (object, fieldName) {
        return object.sort((a, b) => (a[fieldName] < b[fieldName]) ? 1 : ((b[fieldName] < a[fieldName]) ? -1 : 0))
    },
    getAllItemPageSize: function () {
        return 2000;
    },
    getLastDigit: function (decimalNum) {
        return (Number.isInteger(decimalNum) ?
            decimalNum % 10 : decimalNum.toString().slice(-1)
        );
    },
    capitalizeFirstLetter: function (str) {
        if (str == '' || str == undefined || str == null || str == []) return;
        if (str.length == 1)
            return str.toUpperCase()
        let s = str.charAt(0).toUpperCase() + str.slice(1) 
        return s
    }, 
    capitalizeFirstAndLowerCaseOther: function (str) {
        if (str == '' || str == undefined || str == null || str == []) return;
        if (str.length == 1)
            return str.toUpperCase()
        let s = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
        return s
    }, 
    getGenderbyNRIC: function (nric) {
        let gender = "";
        let isEven = nric % 2;

        if(isEven == 0){
            gender = '2';
        }  
        else{
            gender = '1';
        }

        return gender
    },
    getDOBbyNRIC: function (nric) {
        let _dobYear = '19' + nric.substring(0,2);
        let _dobMonth = nric.substring(2,4);
        let _dobDay = nric.substring(4,6);
        let _dob = _dobYear + '/' + _dobMonth + '/' + _dobDay;
        let _birthDate = new Date(_dob); 
        let dob = _birthDate;

        return dob
    },
    formatcountryCode: function (countryCode) {
        return (countryCode && countryCode != "" ? 
            (countryCode == "Malaysia (+60)" ?
                "+60" : (countryCode == "Singapore (+65)" ?
                    "+65" : (countryCode.includes("+") ?
                        countryCode : "+" + countryCode
                    )
                )
            ) : ""
        );
    },
}

export default GlobalFunctionsUtils;