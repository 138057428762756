export const AccessEnum = {
    DASHBOARD : "0",
}

export const QueueAccessEnum = {
    QUEUE_TODAY_VIEW : 1,
    QUEUE_TODAY_ADD : 2,
    QUEUE_TODAY_EDIT : 3,
    QUEUE_TODAY_DELETE : 4,
    QUEUE_TODAY_ENGAGE : 5,
    QUEUE_TODAY_CHECKOUT : 6,

    QUEUE_PAST_VIEW : 7,
    QUEUE_PAST_ADD : 8,
    QUEUE_PAST_EDIT : 9,
    QUEUE_PAST_DELETE : 10,
}

export const PatientAccessEnum = {
    PATIENT_LIST_VIEW : 11,
    PATIENT_LIST_ADD : 12,
    PATIENT_LIST_EDIT : 13,

    PATIENT_NEW_VIEW : 14,
    PATIENT_NEW_ADD : 15,
    PATIENT_NEW_EDIT : 16,
}

export const AppointmentAccessEnum = {
    APPOINTMENT_LIST_VIEW : 17,
    APPOINTMENT_LIST_ADD : 18,
    APPOINTMENT_LIST_EDIT : 19,
    APPOINTMENT_LIST_DELETE : 20,

    APPOINTMENT_CALENDAR_VIEW : 21,
    APPOINTMENT_CALENDAR_ADD : 22,
    APPOINTMENT_CALENDAR_EDIT : 23,
    APPOINTMENT_CALENDAR_DELETE : 24,
}

export const PrescriptionAccessEnum = {
    PRESCRIPTION_VIEW : 25,
    PRESCRIPTION_ADD : 26,
    PRESCRIPTION_EDIT : 27,
    PRESCRIPTION_DELETE : 28,
}

export const InvoicesAccessEnum = {
    INVOICES_VIEW : 29,
    INVOICES_ADD : 30,
    INVOICES_EDIT : 31,
    INVOICES_DELETE : 32,

    INVOICES_DETAILED_VIEW : 33,
    INVOICES_DETAILED_ADD : 34,
    INVOICES_DETAILED_EDIT : 35,
    INVOICES_DETAILED_DELETE : 36,

    GROUP_INVOICES_VIEW : 37,
    GROUP_INVOICES_ADD : 38,
    GROUP_INVOICES_EDIT : 39,
    GROUP_INVOICES_DELETE : 40,

    POSTED_GROUP_INVOICES_VIEW : 41,
    POSTED_GROUP_INVOICES_ADD : 42,
    POSTED_GROUP_INVOICES_EDIT : 43,
    POSTED_GROUP_INVOICES_DELETE : 44,

    QUOTATION_VIEW : 45,
    QUOTATION_ADD : 46,
    QUOTATION_EDIT : 47,
    QUOTATION_DELETE : 48,

    CREDIT_NOTE_VIEW : 49,
    CREDIT_NOTE_ADD : 50,
    CREDIT_NOTE_EDIT : 51,
    CREDIT_NOTE_DELETE : 52,
}

export const InventoryAccessEnum = {
    INVENTORY_VIEW : 53,
    INVENTORY_ADD : 54,
    INVENTORY_EDIT : 55,
    INVENTORY_DELETE : 56,
    INVENTORY_VIEW_INACTIVE : 57,

    VENDOR_GOODS_RECEIVED_VIEW : 58,
    VENDOR_GOODS_RECEIVED_ADD : 59,
    VENDOR_GOODS_RECEIVED_EDIT : 60,
    VENDOR_GOODS_RECEIVED_DELETE : 61,

    VENDOR_PURCHASE_ORDER_VIEW : 62,
    VENDOR_PURCHASE_ORDER_ADD : 63,
    VENDOR_PURCHASE_ORDER_EDIT : 64,
    VENDOR_PURCHASE_ORDER_DELETE : 65,

    VENDOR_PURCHASE_RETURN_VIEW : 66,
    VENDOR_PURCHASE_RETURN_ADD : 67,
    VENDOR_PURCHASE_RETURN_EDIT : 68,
    VENDOR_PURCHASE_RETURN_DELETE : 69,

    BRANCH_GOODS_RECEIVED_VIEW : 70,
    BRANCH_GOODS_RECEIVED_ADD : 71,
    BRANCH_GOODS_RECEIVED_EDIT : 72,
    BRANCH_GOODS_RECEIVED_DELETE : 73,
    BRANCH_GOODS_RECEIVED_ACKNOWLEDGE : 74,

    BRANCH_GOODS_RETURN_VIEW : 75, 
    BRANCH_GOODS_RETURN_ADD : 76,
    BRANCH_GOODS_RETURN_EDIT : 77,
    BRANCH_GOODS_RETURN_DELETE : 78,

    BRANCH_STOCK_TRANSFER_VIEW : 79,
    BRANCH_STOCK_TRANSFER_ADD : 80,
    BRANCH_STOCK_TRANSFER_EDIT : 81,
    BRANCH_STOCK_TRANSFER_DELETE : 82,
    BRANCH_STOCK_TRANSFER_ACKNOWLEDGE : 83,
}

export const ReportsAccessEnum = {
    REPORT_MEDICAL_CERIFICATE_VIEW : 84,
    REPORT_INVOICE_VIEW : 85,
    REPORT_PATIENTS_VIEW : 86,
    REPORT_PAYMENTS_VIEW : 87,
    REPORT_ENDOFDAY_VIEW : 88,
    REPORT_AGINGS_VIEW : 89,
    REPORT_CLINIC_SERIVCES_VIEW : 90,
    REPORT_DISPENSE_VIEW : 91,
    REPORT_STOCK_ADJUSTMENT_VIEW : 92,
    REPORT_APPOINTMENTS_VIEW : 93,
    REPORT_QUEUE_VIEW : 94,

    // REPORT_INVENTORY_MOVEMENT_VIEW : 144,
    // REPORT_DAILY_CLOSING_STOCK_VIEW : 145,
    // REPORT_DAILY_DISPENSE_CLOSING_STOCK_VIEW : 146,
    // REPORT_REFERRAL_VIEW : 147,
    // REPORT_CREDIT_NOTE_VIEW : 148,

    //FOR TESTING ONLY -----------------------
    REPORT_INVENTORY_MOVEMENT_VIEW : 84, 
    REPORT_DAILY_CLOSING_STOCK_VIEW : 85,
    REPORT_DAILY_DISPENSE_CLOSING_STOCK_VIEW : 86,
    REPORT_REFERRAL_VIEW : 87,
    REPORT_CREDIT_NOTE_VIEW : 88,
    // -------------------------
}

export const MaintenanceAccessEnum = {
    MAINTENANCE_ROLE_ACCESS_VIEW : 95, 
    MAINTENANCE_ROLE_ACCESS_ADD : 96, 
    MAINTENANCE_ROLE_ACCESS_EDIT : 97,

    MAINTENANCE_CLINIC_VIEW : 98, 
    MAINTENANCE_CLINIC_ADD : 99, 
    MAINTENANCE_CLINIC_EDIT : 100,
    MAINTENANCE_CLINIC_DELETE : 101,

    MAINTENANCE_INSURANCE_VIEW : 102,
    MAINTENANCE_INSURANCE_ADD : 103,
    MAINTENANCE_INSURANCE_EDIT : 104,
    MAINTENANCE_INSURANCE_DELETE : 105,

    MAINTENANCE_SUPPLIER_VIEW : 106,
    MAINTENANCE_SUPPLIER_ADD : 107,
    MAINTENANCE_SUPPLIER_EDIT : 108,
    MAINTENANCE_SUPPLIER_DELETE : 109,

    MAINTENANCE_INVENTORY_SETUP_VIEW : 110, 
    MAINTENANCE_INVENTORY_SETUP_ADD : 111,
    MAINTENANCE_INVENTORY_SETUP_EDIT : 112,
    MAINTENANCE_INVENTORY_SETUP_DELETE : 113,

    MAINTENANCE_INVENTORY_VIEW : 114, 
    MAINTENANCE_INVENTORY_ADD : 115,
    MAINTENANCE_INVENTORY_EDIT : 116,
    MAINTENANCE_INVENTORY_DELETE : 117,

    MAINTENANCE_USER_VIEW : 118,
    MAINTENANCE_USER_ADD : 119,
    MAINTENANCE_USER_EDIT : 120,
    MAINTENANCE_USER_DELETE : 121,

    MAINTENANCE_SERVICE_VIEW : 122,
    MAINTENANCE_SERVICE_ADD : 123,
    MAINTENANCE_SERVICE_EDIT : 124,
    MAINTENANCE_SERVICE_DELETE : 125,

    MAINTENANCE_SERVICE_CATEGORY_VIEW : 126,
    MAINTENANCE_SERVICE_CATEGORY_ADD : 127,
    MAINTENANCE_SERVICE_CATEGORY_EDIT : 128,
    MAINTENANCE_SERVICE_CATEGORY_DELETE : 129,

    MAINTENANCE_PRICE_GROUP_VIEW : 130,
    MAINTENANCE_PRICE_GROUP_ADD : 131,
    MAINTENANCE_PRICE_GROUP_EDIT : 132,
    MAINTENANCE_PRICE_GROUP_DELETE : 133,

    MAINTENANCE_GENERAL_SETTINGS_VIEW : 134,
    MAINTENANCE_GENERAL_SETTINGS_EDIT : 135,

    MAINTENANCE_BUSINESS_GROUP_VIEW: 136,
    MAINTENANCE_BUSINESS_GROUP_ADD: 137,
    MAINTENANCE_BUSINESS_GROUP_EDIT: 138,
    MAINTENANCE_BUSINESS_GROUP_DELETE: 139,

    MAINTENANCE_MEMBERSHIP_POINT_VIEW : 140,
    MAINTENANCE_MEMBERSHIP_POINT_ADD : 141,
    MAINTENANCE_MEMBERSHIP_POINT_EDIT : 142,
    MAINTENANCE_MEMBERSHIP_POINT_DELETE : 143,

//latest for enum: 148

    // MAINTENANCE_PACKAGE_VIEW: 144,
    // MAINTENANCE_PACKAGE_ADD: 145,
    // MAINTENANCE_PACKAGE_EDIT: 146,
    // MAINTENANCE_PACKAGE_DELETE: 147,

    // MAINTENANCE_TV_ADS_VIEW: 148,
    // MAINTENANCE_TV_ADS_ADD: 149,
    // MAINTENANCE_TV_ADS_EDIT: 150,
    // MAINTENANCE_TV_ADS_DELETE: 151,

    // MAINTENANCE_TV_ADS_RESOURCES_VIEW: 152,
    // MAINTENANCE_TV_ADS_RESOURCES_ADD: 153,
    // MAINTENANCE_TV_ADS_RESOURCES_EDIT: 154,
    // MAINTENANCE_TV_ADS_RESOURCES_DELETE: 155,

    // MAINTENANCE_DAILY_CLOSING_STOCK_VIEW: 156,
    // MAINTENANCE_DAILY_CLOSING_STOCK_ADD: 157,
    // MAINTENANCE_DAILY_CLOSING_STOCK_EDIT: 158,
    // MAINTENANCE_DAILY_CLOSING_STOCK_DELETE: 159,

    // MAINTENANCE_DAILY_DISPENSE_CLOSING_STOCK_VIEW: 160,
    // MAINTENANCE_DAILY_DISPENSE_CLOSING_STOCK_ADD: 161,
    // MAINTENANCE_DAILY_DISPENSE_CLOSING_STOCK_EDIT: 162,
    // MAINTENANCE_DAILY_DISPENSE_CLOSING_STOCK_DELETE: 163,

    // MAINTENANCE_VOUCHER_VIEW: 164,
    // MAINTENANCE_VOUCHER_ADD: 165,
    // MAINTENANCE_VOUCHER_EDIT: 166,
    // MAINTENANCE_VOUCHER_DELETE: 167,

    // MAINTENANCE_MAIL_SERVER_VIEW: 168,
    // MAINTENANCE_MAIL_SERVER_ADD: 169,
    // MAINTENANCE_MAIL_SERVER_EDIT: 170,
    // MAINTENANCE_MAIL_SERVER_DELETE: 171,

    // MAINTENANCE_WHATSAPP_NOTIFICATION_VIEW: 172,
    // MAINTENANCE_WHATSAPP_NOTIFICATION_ADD: 173,
    // MAINTENANCE_WHATSAPP_NOTIFICATION_EDIT: 174,
    // MAINTENANCE_WHATSAPP_NOTIFICATION_DELETE: 175,

    // MAINTENANCE_QUEUE_MAINTENANCE_VIEW: 176,
    // MAINTENANCE_QUEUE_MAINTENANCE_ADD: 177,
    // MAINTENANCE_QUEUE_MAINTENANCE_EDIT: 178,
    // MAINTENANCE_QUEUE_MAINTENANCE_DELETE: 179,

    // MAINTENANCE_APPOINTMENT_MAINTENANCE_VIEW: 180,
    // MAINTENANCE_APPOINTMENT_MAINTENANCE_ADD: 181,
    // MAINTENANCE_APPOINTMENT_MAINTENANCE_EDIT: 182,
    // MAINTENANCE_APPOINTMENT_MAINTENANCE_DELETE: 183,

    // MAINTENANCE_LABORATORY_VIEW: 184,
    // MAINTENANCE_LABORATORY_ADD: 185,
    // MAINTENANCE_LABORATORY_EDIT: 186,
    // MAINTENANCE_LABORATORY_DELETE: 187,

    MAINTENANCE_BUSINESS_GROUP_VIEW: 130,
    MAINTENANCE_BUSINESS_GROUP_ADD: 131,
    MAINTENANCE_BUSINESS_GROUP_EDIT: 132,
    MAINTENANCE_BUSINESS_GROUP_DELETE: 133,

    MAINTENANCE_PACKAGE_VIEW: 130,
    MAINTENANCE_PACKAGE_ADD: 131,
    MAINTENANCE_PACKAGE_EDIT: 132,
    MAINTENANCE_PACKAGE_DELETE: 133,

    MAINTENANCE_TV_ADS_VIEW: 130,
    MAINTENANCE_TV_ADS_ADD: 131,
    MAINTENANCE_TV_ADS_EDIT: 132,
    MAINTENANCE_TV_ADS_DELETE: 133,

    MAINTENANCE_TV_ADS_RESOURCES_VIEW: 130,
    MAINTENANCE_TV_ADS_RESOURCES_ADD: 131,
    MAINTENANCE_TV_ADS_RESOURCES_EDIT: 132,
    MAINTENANCE_TV_ADS_RESOURCES_DELETE: 133,

    MAINTENANCE_DAILY_CLOSING_STOCK_VIEW: 130,
    MAINTENANCE_DAILY_CLOSING_STOCK_ADD: 131,
    MAINTENANCE_DAILY_CLOSING_STOCK_EDIT: 132,
    MAINTENANCE_DAILY_CLOSING_STOCK_DELETE: 133,

    MAINTENANCE_DAILY_DISPENSE_CLOSING_STOCK_VIEW: 130,
    MAINTENANCE_DAILY_DISPENSE_CLOSING_STOCK_ADD: 131,
    MAINTENANCE_DAILY_DISPENSE_CLOSING_STOCK_EDIT: 132,
    MAINTENANCE_DAILY_DISPENSE_CLOSING_STOCK_DELETE: 133,

    MAINTENANCE_VOUCHER_VIEW: 130,
    MAINTENANCE_VOUCHER_ADD: 131,
    MAINTENANCE_VOUCHER_EDIT: 132,
    MAINTENANCE_VOUCHER_DELETE: 133,

    MAINTENANCE_MAIL_SERVER_VIEW: 130,
    MAINTENANCE_MAIL_SERVER_ADD: 131,
    MAINTENANCE_MAIL_SERVER_EDIT: 132,
    MAINTENANCE_MAIL_SERVER_DELETE: 133,

    MAINTENANCE_WHATSAPP_NOTIFICATION_VIEW: 130,
    MAINTENANCE_WHATSAPP_NOTIFICATION_ADD: 131,
    MAINTENANCE_WHATSAPP_NOTIFICATION_EDIT: 132,
    MAINTENANCE_WHATSAPP_NOTIFICATION_DELETE: 133,

    MAINTENANCE_QUEUE_MAINTENANCE_VIEW: 130,
    MAINTENANCE_QUEUE_MAINTENANCE_ADD: 131,
    MAINTENANCE_QUEUE_MAINTENANCE_EDIT: 132,
    MAINTENANCE_QUEUE_MAINTENANCE_DELETE: 133,

    MAINTENANCE_APPOINTMENT_MAINTENANCE_VIEW: 130,
    MAINTENANCE_APPOINTMENT_MAINTENANCE_ADD: 131,
    MAINTENANCE_APPOINTMENT_MAINTENANCE_EDIT: 132,
    MAINTENANCE_APPOINTMENT_MAINTENANCE_DELETE: 133,

    MAINTENANCE_LABORATORY_VIEW: 130,
    MAINTENANCE_LABORATORY_ADD: 131,
    MAINTENANCE_LABORATORY_EDIT: 132,
    MAINTENANCE_LABORATORY_DELETE: 133,
}
