import React, { useRef, useState, useEffect } from 'react';

import { useCookies } from 'react-cookie';
import './PageLayout.scss';
import { retryLazyImport } from '../../../../store/SessionManager';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Outlet, useNavigate } from 'react-router-dom';

import {
    Box,
    Button,
    ClickAwayListener,
    Grow,
    ListSubheader,
    MenuItem,
    MenuList,
    Paper,
    Popper,
} from '@mui/material/';

import {
    ArrowUpwardOutlinedIcon,
} from '../../../../utilities/Icons/Icons';
import GlobalFunctionsUtils from '../../../../utilities/GlobalFunctionUtils';
import NavigationSideMenu from '../NavigationMenu/NavigationSideMenu';
import { useLoadingScreen } from '../../../../store/LoadingManager';

import AccessHelper from '../../../../utilities/Helpers/AccessHelper';
const NavigationHeaders = React.lazy(() => retryLazyImport(() => import('../../../CommonComponents/Layout/NavigationMenu/NavigationHeaders')));
const NavigationMenu = React.lazy(() => retryLazyImport(() => import('../../../CommonComponents/Layout/NavigationMenu/NavigationMenu')));
const Footer = React.lazy(() => retryLazyImport(() => import('../../../CommonComponents/Layout/Footer/Footer')));
const BreadcrumbsPage = React.lazy(() => retryLazyImport(() => import('../../../CommonComponents/Layout/BreadcrumbsPage/BreadcrumbsPage')));

const CustomDialog = React.lazy(() => retryLazyImport(() => import('../../../CommonComponents/CustomDialog/CustomDialog')));
const AdvanceFilterDrawer = React.lazy(() => retryLazyImport(() => import('../../../CommonComponents/AdvanceFilterDrawer/AdvanceFilterDrawer')));

const RootLayout = ({
    darkMode = false,
    setDarkMode = null,
    toggledMenu = false,
    setToggledMenu = null,
    DefaultGeneralSettings = null,
    errorOutlet
}) => {
    type Anchor = 'top' | 'left' | 'bottom' | 'right';
    let _MenuItems = AccessHelper.hasAccessMenu();
    const navigate = useNavigate()

    const { loadingState, setLoadingState } = useLoadingScreen();
    // Use states
    const [anchorDrawer, setAnchorDrawer] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const [openDialog, setOpenDialog] = useState(false);
    const [dialogData, setDialogData] = useState({
        title: null,
        content: null,
        clearFormData: null,
        footerContent: null,
    });
    const [pageDetails, setPageDetails] = useState({
        pageId: "",
        patientId: "",
        idType: "",
        pageTab: "",
        pageItemDetailsFromPage: "",

    });
    const [pos, setPos] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [drawerMenu, setDrawerMenu] = useState([]);
    const [selectedDrawerMenu, setSelectedDrawerMenu] = useState()

    const openPopper = Boolean(anchorEl);

    //Variables
    const [cookies, setCookie, removeCookie] = useCookies(['navigatedPages']);
    const ref = useRef();
    const anchorRef = useRef(null);


    const handleToggle = (e: React.MouseEvent<HTMLButtonElement>, menuItem, selectedMenuArr) => {
        // setOpenPopper((prevOpen) => !prevOpen);
        setAnchorEl(e.currentTarget)
        setDrawerMenu(menuItem)
    };

    const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        if (anchorEl != null)
            setAnchorEl(null);
        // setOpenPopper(false);
    };

    function handleListKeyDown(event: React.MouseEvent<HTMLButtonElement>) {
        if (event.key === 'Tab') {
            event.preventDefault();
            handleClose(event)
        } else if (event.key === 'Escape') {
            handleClose(event)
        }
    }

    let navigationMenuProps = {
        darkMode: darkMode,
        setDarkMode: setDarkMode,
        navigatedPages: cookies.navigatedPages,
        openDrawer: toggledMenu,
        clickIconMenu: handleToggle,
        handleClosePopper: handleClose
    },
        navigationHeaderProps = {
            darkMode: darkMode,
            setDarkMode: setDarkMode,
            navigatedPages: cookies.navigatedPages,
            toggledMenu: toggledMenu,
            setToggledMenu: setToggledMenu,
        },
        contentPageProps = {
            darkMode: darkMode,
            navigatedPages: cookies.navigatedPages,
        },
        breadcrumbsProps = {
            darkMode: darkMode,
            navigatedPages: cookies.navigatedPages,
        };

    const theme = createTheme({
        palette: {
            mode: (darkMode == 'true' ? 'dark' : 'light'),
        },
    });

    //Event Handlers

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        setAnchorDrawer({ ...anchorDrawer, [anchor]: open });
    };


    const renderSubmenu = (subMenu, leftPadding) => <>
        {subMenu?.map(x => <>
            <MenuItem sx={{ pt: 1, pb: 1, pl: leftPadding, color: selectedDrawerMenu == x.tabName ? '#25a0e2' : '#555555', background: selectedDrawerMenu == x.tabName ? '#def1fb50' : 'white' }}
                onClick={() => {
                    if (!x.subMenuItems || x.subMenuItems == []) {
                        setSelectedDrawerMenu(x.tabName);
                        navigate(x.navigateTo, {
                            state: {
                                tabName: x.tabName,
                                parentName: x.parentName
                            }
                        });
                    }

                    handleClose();
                } }>
                {x.name}
            </MenuItem>
            {x.subMenuItems?.length > 0 && renderSubmenu(x.subMenuItems, leftPadding + 3)}
        </>
        )}
    </>;

    const renderIconMenuPopper = (menuItem) => <>
        <div style={{ padding: '15px 10px 5px 10px', color: 'black', fontWeight: '600', fontSize: '15px' }}>{menuItem.name}</div>
        <MenuList
            autoFocusItem={openPopper}
            id="composition-menu"
            aria-labelledby="composition-button"
            onKeyDown={handleListKeyDown}
        >
            {menuItem?.subMenuItems && renderSubmenu(menuItem?.subMenuItems, 2)}
        </MenuList>
    </>;

    const renderIconDrawerMenu = () => <Popper
        open={openPopper}
        anchorEl={anchorEl}
        role={undefined}
        placement="right-start"
        transition
        // disablePortal
        sx={{ ml: '10px !important', zIndex: '99999'}}
    >
        {({ TransitionProps, placement }) => (
            <Grow
                {...TransitionProps}
                // style={{
                //     transformOrigin: 'right-start',
                // }}
            >
                <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                        {renderIconMenuPopper(drawerMenu)}
                    </ClickAwayListener>
                </Paper>
            </Grow>
        )}
    </Popper>

    navigationMenuProps = {
        ...navigationMenuProps,
        DefaultGeneralSettings: DefaultGeneralSettings,
    }

    navigationHeaderProps = {
        ...navigationHeaderProps,
        DefaultGeneralSettings: DefaultGeneralSettings,
    }

    contentPageProps = {
        ...contentPageProps,
        anchorDrawer: anchorDrawer,
        setAnchorDrawer: setAnchorDrawer,
        toggleDrawer: toggleDrawer,
        pageDetails: pageDetails,
    }

    breadcrumbsProps = {
        ...breadcrumbsProps,
    }

    const advanceFilterDrawerProps = {
        darkMode: darkMode,
        anchorDrawer: anchorDrawer,
        setAnchorDrawer: setAnchorDrawer,
        toggleDrawer: toggleDrawer,
    };

    const customDialogProps = {
        darkMode: darkMode,
        openDialog: openDialog,
        setOpenDialog: setOpenDialog,
        dialogData: dialogData,
        setDialogData: setDialogData,
    };

    const handleTop = () => {
        ref.current.scrollTo({ top: 0, behavior: 'smooth' });
        setPos(false);
    };

    const handleScroll = () => {
        if (ref.current.scrollTop > 50) {
            if (!pos) {
                setPos(true);
            }
        } else {
            if (pos) {
                setPos(false);
            }
        }
    };

    useEffect(() => {
        const temp = ref.current;
        if (temp != undefined) {
            temp.addEventListener("scroll", handleScroll);
            return () => temp.removeEventListener("scroll", handleScroll);
        }
    });

    return <div className="alproClinicMainContainer">
        <ThemeProvider theme={theme}>
            {openPopper && renderIconDrawerMenu()}
            <Box sx={{ display: 'flex' }}>
                {/* <NavigationMenu {...navigationMenuProps} /> */}
                <NavigationSideMenu {...navigationMenuProps} />
                <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
                    <div className="pageComponentMainContainer">
                        <NavigationHeaders {...navigationHeaderProps} />
                        <BreadcrumbsPage {...breadcrumbsProps} />
                        {/* {renderPageComponent()} */}
                        <div className="pageComponentContainer" id="pageComponentContainer" ref={ref}>
                            <div className="pageContent">
                                {errorOutlet ? errorOutlet : <Outlet />}
                            </div>
                        </div>
                        <Footer />
                        <Button
                            key={GlobalFunctionsUtils.getRanHex(24)}
                            style={{
                                display: pos ? "block" : "none"
                            }}
                            className="scrollToTopBtn"
                            variant="outlined"
                            size="small"
                            onClick={handleTop}>
                            <ArrowUpwardOutlinedIcon className="scrollToTopBtnIcon notMUIIcon" />
                        </Button>
                    </div>
                </Box>
            </Box>
        </ThemeProvider>
    </div>
}

export default RootLayout;