import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import LocalStorageHelper from "../../../../utilities/LocalStorageHelper";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Link } from 'react-router-dom';
import Tooltip from '../../Tooltip/Tooltip';

export default function NavigationSideMenuItem({
    isSelected = false,
    isOpenSubMenu = false,
    isDrawerOpen,
    menuItem,
    handleClickMenu,
    leftPadding }) {
    
    const textColor = isSelected ? '#25a0e2' : '#7c7f90'

    const RightClickComponent = (props) => {
        return <Link
            {...props}
            href={props.href}
            to={props.href}
            state={{
                    menuPath:
                    {
                        tabName: props.tabName,
                        parentName: props.parentName
                    }
                }}/>
    }

    let linkOtherProps = {};

    if (!menuItem.hasSubMenu) {
        linkOtherProps = {
            parentName: menuItem.parentName,
            tabName: menuItem.tabName,
            href: menuItem.navigateTo,
            component: RightClickComponent
        };
    }

    const renderListIcon = (menuItem) => {
        if (menuItem.icon) {
            return <ListItemIcon sx={{
                minWidth: 0,
                justifyContent: 'center',
                mr: isDrawerOpen ? 3 : 'auto',
                color: textColor
            }}>
                {menuItem.icon}

            </ListItemIcon>
        } else 
            return <></>
    }

    return <ListItemButton
        onClick={(e) => handleClickMenu(e)}
            sx={{minHeight: 45,
                pr: 2.5,
                pl: leftPadding,
                pt: 0,
                pb: 0,
                mt: 0,
                mb: 0,
                justifyContent: isDrawerOpen ? 'initial' : 'center'}}
        {...linkOtherProps}>
        {isDrawerOpen ? renderListIcon(menuItem) : <Tooltip
            style={{ width: "10%" }}
            tooltipName={menuItem.name}
            placement="top"
            darkMode={LocalStorageHelper.isDarkMode()}
            tooltipContent={menuItem.icon && <ListItemIcon sx={{
                minWidth: 0,
                justifyContent: 'center',
                mr: isDrawerOpen ? 3 : 'auto',
                color: textColor
            }}>
                {menuItem.icon}
            
            </ListItemIcon>}
        />}
            <ListItemText
            primary={menuItem.name}
            disableTypography
                style={{ color: textColor, textWrap: 'auto' }}
                sx={[{ opacity: isDrawerOpen ? 1 : 0, fontSize: '0.9rem !important' }]}/>
                                    
                 {menuItem.hasSubMenu && isDrawerOpen ?
                        (isOpenSubMenu
                            ? <ExpandLess sx={{ color: textColor }} />
                            : <ExpandMore sx={{ color: textColor }} />)
                                                : <></>}
        </ListItemButton>
};